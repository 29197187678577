import './logo.css';

export default function BusinessFlixLogo() {
    return (
        <a href="#heroSection">
            <div className="logo-business-flix">
                <b>Business</b>Flix
            </div>
        </a>
    );
}